import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Button, Card, CardImg, CardBody, CardTitle, CardFooter, CardHeader } from 'reactstrap';
import { Icon } from '../../../Components/Component';
import { ShimmerText, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects';
import { FixedSizeList as List } from 'react-window';
import placeholderimage from '../../../images/News/NewsDefault.png';

const NewsDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentNews, setCurrentNews] = useState(null);
    const [moreNews, setMoreNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    const fetchAllNews = async (totalPages = 5) => {
        const baseUrl = `${process.env.REACT_APP_NEWS_API_URL}api/v1/public/get-public-news`;
        let allNews = [];
        for (let page = 1; page <= totalPages; page++) {
            const url = `${baseUrl}?limit=150&order=desc&page=${page}`;
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();

                if (data.data && data.data.news && Array.isArray(data.data.news)) {
                    allNews = [...allNews, ...data.data.news];

                    if (data.data.news.length < 150) {
                        break;
                    }
                } else {
                    console.error(`No news data found on page ${page}`);
                    break;
                }
            } catch (error) {
                console.error(`Fetch error on page ${page}:`, error);
                break;
            }
        }
        return allNews;
    };

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const allNews = await fetchAllNews();
                const newsIndex = allNews.findIndex(news => news.id === parseInt(id));
                if (newsIndex !== -1) {
                    setCurrentNews(allNews[newsIndex]);
                    setMoreNews(allNews.filter((_, i) => i !== newsIndex));
                } else {
                    navigate('404');
                }
            } catch (error) {
                console.error("Fetch error:", error);
                navigate('404');
            } finally {
                setLoading(false);
            }
        };
        fetchNewsData();
    }, [id, navigate]);

    const placeholderImage = placeholderimage;
    const handleImageError = (event) => {
        event.target.src = placeholderImage;
    };

    const renderShimmer = () => (
        <div>
            <ShimmerTitle line={1} gap={10} />
            <ShimmerText line={2} gap={15} />
            <ShimmerThumbnail height={300} />
            <ShimmerText line={4} gap={10} />
        </div>
    );

    const NewsItem = ({ index, style }) => {
        const item = moreNews[index];
        return (
            <div style={style} className='content'>
                <Link to={`/news/${item.id}`}>
                    <Card className={`mt-0 h-100 image-zoom-container border-bottom border-bottom-1 rounded-0`} style={{ cursor: 'pointer' }}>
                        {/* <CardHeader className='bg-transparent px-1'>
                            {item.catgory}
                        </CardHeader> */}
                        <Row className='g-3'>
                            <Col md={4} className='px-1'>
                                <div className='image-zoom-container h-100 p-1 rounded-0 '>
                                    <figure className='m-0 h-100 '>
                                        <CardImg top src={item.image || placeholderimage} alt={item.title} loading="lazy" className='zoom-image border border-1 rounded-0' onError={handleImageError} />
                                    </figure>
                                </div>
                            </Col>
                            <Col md={8} className='px-1'>
                                <CardBody className='p-0'>
                                    <CardTitle className='text-black fs-6'>
                                        {truncateText(item.title, 80)}
                                    </CardTitle>
                                    <CardFooter className='bg-transparent py-0 text-gray text-end'>
                                        {new Date(item.publish_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                                    </CardFooter>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                    {/* <div className='border-bottom'>
                        <div className='py-3 '>
                            <h5>{item.title}</h5>
                            <p>{new Date(item.publish_date).toLocaleString()}</p>
                        </div>
                    </div> */}
                </Link>
            </div>
        );
    };

    if (loading) {
        return (
            <Container className='py-5'>
                <Row>
                    <Col md={8}>
                        {renderShimmer()}
                    </Col>
                    <Col md={4}>
                        <ShimmerTitle line={1} gap={10} />
                        {[...Array(3)].map((_, index) => (
                            <div key={index} className="mb-3">
                                <ShimmerTitle line={1} gap={10} />
                                <ShimmerText line={1} gap={5} />
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        );
    }

    if (!currentNews) {
        return navigate('404');
    }

    const truncateText = (text, maxLength) => {
        if (!text || text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };

    return (
        <React.Fragment>
            <Container className='py-5'>
                <div className='content row'>
                    <Col md={8}>
                        <Row className='g-4 justify-content-center'>
                            <div className='col-md-12'>
                                <div>
                                    <h3 className='text-black ff'>{currentNews.title}</h3>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <img
                                    src={currentNews.image || placeholderImage}
                                    alt={currentNews.title}
                                    className="img-fluid"
                                    onError={handleImageError}
                                />
                                <div className='d-flex align-items-center mt-3'>
                                    <i className={`bi bi-person-fill pe-2 ${process.env.REACT_APP_TEXT_COLOR}`}></i>
                                    <p className={`${process.env.REACT_APP_TEXT_COLOR} text-start mb-0 ff`}>{currentNews.author || 'Unknown'}</p>
                                    <span className={`${process.env.REACT_APP_TEXT_COLOR} ps-3`}>{new Date(currentNews.publish_date).toLocaleString()}</span>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <p className='ff text-black fs-6'>
                                    {isExpanded ? currentNews.news_text : truncateText(currentNews.news_text, 500)}
                                </p>
                                <Button className="btn-dim" color="primary" onClick={() => setIsExpanded(!isExpanded)} >
                                    {isExpanded ? 'Show Less' : 'Read More'}
                                </Button>
                            </div>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <div className='content'>
                            <h4 className='text-capitalize text-primary text-start fw-bold ff' style={{ letterSpacing: '2px' }}>More News<Icon name="chevron-right" className={'align-bottom'} /></h4>
                            <List className="hide-scrollbar" height={600} itemCount={moreNews.length} itemSize={160} width="100%" >
                                {NewsItem}
                            </List>
                        </div>
                    </Col>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default NewsDetails;