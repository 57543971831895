import React, { useState, useEffect, useCallback, useMemo } from "react";
import { getCookie } from "../utils/Utils";
import { Link, useParams } from "react-router-dom";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "../assets/css/blog.css";
import WaveForm from "./user/WaveForm";
import PlayList from "./user/PlayList";
import demoAudio from "../track/track1.mp3";
import "../assets/css/style.css";

const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options).replace(/\//g, "-");
};

const getCategoryName = (categoryId) => {
  const categories = {
    "TECH TRENDS": "Tech Trends",
    "ARTIFICIAL INTELLIGENCE": "Artificial Intelligence",
    "PRODUCT REVIEWS": "Product Reviews",
    "CLOUD COMPUTING": "Cloud Computing",
    "TECH NEWS": "Tech News",
    "TECH CONFERENCES": "Tech Conference",
  };
  return categories[categoryId] || "";
};

export default function Blogdetailcard({ Blogtitle, Blogimg, is_video, BlogVideo }) {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [audioFilePath, setAudioFile] = useState(demoAudio);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const fetchBlogData = useCallback(async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
      const formData = new FormData();
      formData.append("id", id);
      const response = await fetch(apiUrl, {
        headers: { authToken: getCookie("authToken") },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          setBlog(data.data?.blogs);
          setAudioFile(data.data.blogs.blog_audio || demoAudio);

          const contentRaw = JSON.parse(data.data?.blogs.description);
          const contentState = convertFromRaw(contentRaw);
          setEditorState(EditorState.createWithContent(contentState));
        } else {
          console.error("Error fetching blog data", data.message);
        }
      } else {
        console.error("Error fetching blog data", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchBlogData();
  }, [fetchBlogData]);

  const categoryName = useMemo(() => (blog ? getCategoryName(blog.categories) : ""), [blog]);

  return (
    <div>
      <div className="card" style={{ backgroundColor: process.env.REACT_APP_BLOGDETAILS_BACKGROUND_COLOR }}>
        <div className="card-body">
          <div className="card-header bg-transparent border-0 text-start m-0 px-0">
            <span
              className="px-3 py-2 rounded"
              style={{
                borderLeft: `3px solid ${process.env.REACT_APP_SIDEBAR_LIST_LEFT_BORDER_COLOR}`,
                backgroundColor: process.env.REACT_APP_SIDEBAR_LIST_BACKGROUND_COLOR,
              }}
            >
              {blog && (
                <Link
                  className={`text-decoration-none ${process.env.REACT_APP_TEXT_COLOR}`}
                  style={{ textAlign: "justify" }}
                >
                  {categoryName}
                </Link>
              )}
            </span>
          </div>
          <div className="my-2">
            <h3 className={`card-title fw-bold text-start ${process.env.REACT_APP_TEXT_COLOR}`}>{Blogtitle}</h3>
          </div>
          <div className="blog-detail-image">
            {is_video ? (
              <video controls className="card-img rounded-0">
                <source src={BlogVideo} type="video/mp4" />
              </video>
            ) : (
              <img
                src={Blogimg}
                className="card-img rounded-0"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                  objectFit: "contain",
                  maxHeight: "500px",
                }}
                alt="Blog"
              />
            )}
          </div>
          {blog && (
            <div className="card-body row justify-content-between">
              <div className="recent-post d-flex">
                <div className="col-6">
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-start me-auto`}>
                    <i className={`bi bi-person-fill ${process.env.REACT_APP_TEXT_COLOR}`}></i>
                    <span className="ms-2">{blog.author}</span>
                  </p>
                </div>
                <div className="col-6">
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-end`}>
                    <i className={`bi bi-calendar-date ms-2 ${process.env.REACT_APP_TEXT_COLOR}`}></i>
                    <span className="ms-2">{formatDate(blog.created_at)}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {editorState && (
            <Editor readOnly={true} editorState={editorState} toolbarHidden={true} style={{ textAlign: "left" }} />
          )}
          {/* <WaveForm url={audioFilePath} /> */}
          <PlayList audioFilePath={audioFilePath} />
        </div>
      </div>
    </div>
  );
}
